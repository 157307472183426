@import 'variables';

$font-family-base: 'Fira Sans';
$headings-font-weight: 600;

$table-striped-bg: $lightGray;

$table-cell-vertical-align: middle;
$table-cell-padding-x: 2rem;


$navbar-light-color: rgba($black, .80);
$navbar-light-active-color: $primary;
$navbar-light-brand-hover-color: $indigo;

$breadcrumb-divider: quote(">");

$box-shadow: 0 .25rem .5rem rgba(70, 103, 190, 0.45);

$popover-body-padding-y: 0;
$popover-body-padding-x: 0;


li::marker {
  color: $primary;
}

.table-sm {
  font-size: 0.6rem;
}

.table-striped {
  thead {
    text-align: left;

    tr:last-child th {
      box-shadow: 0 2px 25px rgba(0, 0, 0, 0.09) !important;
    }

    tr:not(:last-child) th {
      border-bottom: $gray solid 1px;
    }
  }
}

.btn-primary {
  color: $white !important;
}
