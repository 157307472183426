/*Daniil Park 31.08.2021*/

:root {
    --main-alignment: 1200px;
    --top-panel-height: 70px;
    --top-panel-name-margin-top: 19px;

    --main-width-phone: 100%;
}

.top-panel {
    width: var(--main-alignment);
    height: var(--top-panel-height);
    background-color: #f9f9f9;
    margin:auto;
}
.top-panel-container {
    position: relative;
    width: var(--main-alignment);
    height: var(--top-panel-height);
    background-color: #f9f9f9;
}

.top-panel-name {
    width: 5%;
    max-width: 5%;
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 31px;
    color: #5672ca;
    display: inline-block;
    margin-top: var(--top-panel-name-margin-top);
    margin-left: 20px;
}

.top-panel-button-collection {
    float: right;
}
.top-panel-button {
    display: inline-block;
    border-right: 1px solid rgba(0, 0, 0, 0.16);
    margin: 20px 15px 0 0px;

    padding: 0px 15px 0px 0px;
}

.left {
    float: left;
}
.right {
    float: right;
}

.chronicles-img {
    width: inherit;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

li.active {
    filter: contrast(200%);
}

.ctable {
    width: 100%;
}

.td-left {
    text-align: left;
}

.td-right {
    text-align: end;
}

.td-center {
    text-align: center;
}

.sub {
    margin: 10px 0px 0px 15px;

    font-style: normal;
    font-weight: normal;
    line-height: 26px;
    color: #747474;
}

.x {
    position: absolute;
    right: 30px;
    top: 30px;
}

.phone-foot {
    display: none;
    margin-left: -20px;
}

@keyframes opacitizer {
    from { opacity: 0;}
    to {opacity: 1;}
}


@media screen and (max-width: 700px) {

    .res-row {
        margin-top: 10px;

        float: left;

        font-family: Fira Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;

        color: #808080;
    }

    .ctable {
        margin-bottom: 40px;
    }

    .adapt-h1 {

        margin-left: 10px;
        font-family: Fira Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 39px;
        /* or 217% */


        color: #000000;
    }

    .phone-foot {
        display: inline;
        margin-left: -20px;
    }

    .category-details {
        display: inline-block;
        position: absolute;
        bottom: 10px;
        left: 15px;

        font-family: Fira Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #5d85ef;
    }

    .category-letter {
        position: absolute;
        top: 5px;
        left: 5px;

        font-family: Fira Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #212121;

        max-width: 80%;
        text-align: left;
    }

    .f-table {
        display: none;
    }

    .top-panel-variations {
        display: none;
    }

    .top-panel {
        width: var(--main-width-phone);
        height: var(--top-panel-height);
        background-color: #f9f9f9;
    }

    .top-panel-container {
        position: relative;
        width: var(--main-width-phone);
        height: var(--top-panel-height);
        background-color: #f9f9f9;
    }

    .top-panel-button {
        display: inline-block;
        border-right: 1px solid rgba(0, 0, 0, 0.16);
        margin: 20px 12px 0 0px;
        padding: 0px 8px 0px 0px;
    }

    .top-panel-name {
        width: 3%;
        max-width: 3%;
        font-style: normal;
        font-family: Bebas Neue;
        font-weight: 700;
        font-size: 22px;
        line-height: 40px;
        color: #5672ca;
        display: inline-block;
        margin-top: var(--top-panel-name-margin-top);
        margin-left: 10px;
    }

    .show {
        display: initial;
    }

    .ctable td {
        display: block;
    }

    .category {
        position: relative;
        display: block;
        margin-left: 5%;
        text-align: left;

        width: 90%;
        height: 120px;

        background-size:     cover;
        background-repeat:   no-repeat;
        background-position: center center;
        border-radius: 3px;
    }

  }

/*next pages*/

.res {
    margin: 30px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.res-row {
    float: left;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;

    color: #808080;
}


.con-page {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

th {
    font-style: normal;
    font-weight: 500;
    line-height: 19px;

    color: #454545;
}

.td-bb {
  border-bottom: 1px solid #E6E6E6;
}

.td-20 {
    padding: 15px 0px 15px 25px;

    width: 20%;
    border-bottom: 1px solid #E6E6E6;

    font-style: normal;
    font-weight: 500;
    line-height: 19px;

    color: #454545;
}

.td-80 {

    padding: 15px 0px 15px 25px;

    border-bottom: 1px solid #E6E6E6;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;

    color: #727272;
}


.td-50 {
    padding: 15px 15% 15px 25px;

    width: 50%;
    border-bottom: 1px solid #E6E6E6;
    border: 1px solid #E6E6E6;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;

    color: #727272;
}

.td-10 {
    padding: 15px 10px 15px 10px;
    text-align: center;

    width: 2%;
    border-bottom: 1px solid #E6E6E6;
    border: 1px solid #E6E6E6;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;

    color: #727272;
}

.td-15 {
    padding: 15px 0px 15px 25px;

    width: 15%;
    border-bottom: 1px solid #E6E6E6;
    border: 1px solid #E6E6E6;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;

    color: #727272;
}


.td-5 {
    padding: auto;
    text-align: center;

    width: 5%;
    border-bottom: 1px solid #E6E6E6;
    border: 1px solid #E6E6E6;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;

    color: #727272;
}

.back-thin {
    margin: 0px 0px 30px 0px;
    display: block;

    font-style: normal;
    font-weight: 500;
    line-height: 19px;

    color: #5D85EF;
}

.back-thin::before {
    content: "";
}

.con-a-c {
    position: relative;
    margin: 20px 0px 20px 0px;
    text-align: center;
}

.back-bold {
    padding: 15px 0px 0px 0px;

    display: inline-block;
    width: 216px;
    height: 46px;

    background: #5D85EF;
    box-shadow: 0px 6px 13px rgba(70, 103, 190, 0.45);
    border-radius: 3px;

    font-style: normal;
    font-weight: 500;
    line-height: 19px;

    color: #FFFFFF;
}

.sells-con {
    margin: 15px 0px 0px 0px;
    padding: 0px 0px 35px 0px;
    width: 100%;
    height: auto;

    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.sells-single-block {
    position: relative;

    display: inline-block;
    margin: 45px 25px 0px 55px ;

    width: 206px;
    height: 61.8px;

    background: #FFFFFF;
    box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
}

.sells-single-block-circle {
    position: absolute;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    right: calc(-32px/2);
    top: calc(61.8px/4);
    background: #5D85EF;
}


.m-b {
    margin-bottom: 25px;
}

.date {
    position: absolute;
    bottom: 30px;
    left: 25px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.slasher {

    position: absolute;
    bottom: 30px;
    left: 130px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.views {
    position: absolute;
    bottom: 30px;
    left: 150px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.news-height {
  height: 477px;
}

.con-news3-body {
    margin: 50px 0px 0px 0px;
}


.news-3-p {
    display: block;
    margin: 20px 0px 0px 0px;
    width: 100%;

    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.1rem;
    /* or 111% */
    color: #606060;

}



/*#news 4*/

.news4-pic {
    position: relative;
    float: left;
    width: 50%;
    padding: 0px 20px 0px 0px;

    border-radius: 3px 3px 0px 0px;
}

.news4-pic > img {
    width: 100%;
}

.con-news4-body {
    width: 50%;
    position: relative;
    float: right;
    padding: 0px 0px 0px 20px;
}

.news-4-h1 {

    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.4rem;
    /* or 125% */


    color: #000000;

}

.phone-block-f {
    display: inline-block;
    width: 40%;
    float: left;
}

.p-h1 {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 20px;

    color: #5672CA;
}

.p-h2 {
    margin: 15px 0px 15px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 12px;
    color: #5672CA;
}

.p-h3 {
    margin: 10px 0px 10px 0px;

    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;

    color: #353535;
}

.phone-block-s {
    display: inline-block;
    width: 40%;
}

.phone-block-t {
    display: inline-block;
    width: 14%;
    float: right;
}

.phone-block-t {
    display: inline-block;
    width: 14%;
    float: right;
}

.phone-block-t {
    display: inline-block;
    width: 14%;
    float: right;
}

.phone-block-t {
    display: inline-block;
    width: 14%;
    float: right;
}

.right-buts {
    float: right;
}

.m-r {
    margin-right: 10px;
}

.m-t {
    margin-top: 50px;
}

.sq-but {
    position: relative;
    display: inline-block;

    width: 18px;
    height: 18px;
}

.square-1 {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #999999;
}

.square-2 {
    bottom: 0px;
    left: 0px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #999999;
}

.square-3 {
    top: 0px;
    right: 0px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #999999;
}

.square-4 {
    bottom: 0px;
    right: 0px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #999999;
}


.line-but {
    position: relative;
    display: inline-block;

    width: 18px;
    height: 18px;
}

.line-1 {
    top: 0px;

    position: absolute;
    width: 18px;
    height: 3px;
    background: #C4C4C4;
}

.line-2 {
    top: 7px;

    position: absolute;
    width: 18px;
    height: 3px;
    background: #C4C4C4;
}

.line-3 {
    top: 15px;

    position: absolute;
    width: 18px;
    height: 3px;
    background: #C4C4C4;
}

.show-as-list-button {
    position: absolute;
    top: 33px;
    left: 47px;
    padding: 5px 24px 6px 18.59px;
    width: 143px;
    height: 32px;

    background: #5D85EF;
    box-shadow: 0px 0px 13px rgba(70, 103, 190, 0.45);
    border-radius: 3px;

    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    text-decoration-line: underline;
    color: white;

}

.show-as-map-button {
    position: absolute;
    top: 33px;
    left: 190px;

    padding: 6px 13px 5px 31px;
    width: 150px;
    height: 32px;

    background: #F2F2F2;
    border-radius: 3px;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    color: #6B6B6B;
}

.city-picker {
    position: absolute;
    top: 39px;
    left: calc(190px + 150px + 31px);


    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    color: #525252;
}

.deal-list-top-panel {
    position: relative;
    height: 98px;
    margin-top: 15.8px;
    border-bottom: 1px solid #E6E6E6;
}

.about-image {
    float: left;
    padding: 0px 31px 23px 0px;
}

.arrow-left {
    /*position: absolute;
    top: calc(46px/3);*/
    width:15px; height: 15px;
    margin-right: 10px;
    transform: rotate(180deg);
    filter: brightness(0) invert(1);
}

.a-left {
    width:15px; height: 15px;
    margin-right: 10px;
    transform: rotate(180deg);
    filter: brightness(0) invert(1);
}


.td-left-bord {
    border-left: 1px solid #E6E6E6;
}

.n-active {
    background: #5D85EF;
}



/* НАЧАЛО СТИЛИ ДЛЯ materials */

.search-panel {
    margin-top: 26px;;
}


.search-panel div {
    display: inline-block;
    vertical-align: bottom;
}

.searchbar-wrapper {
    margin-right: 25px;
}

.searchbar-wrapper input {
    width: 840px;
    height: 42px;
    padding: 0px 0px 0px 15px;

    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
}

.searchbar-wrapper input::placeholder {
    position: absolute;
    left: 15px;
    top: 11px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    color: #B4B4B4;
}

.searchbar-wrapper img {
    position: absolute;
    top: 14px;
    left: 810px;
}

.searchbar-wrapper input:focus {
    outline: none;
}

.search-btn {
    padding: 0px;
    width: 135px;
    height: 42px;
    margin: 0 20px 0 0 ;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #FFFFFF;
    border: none;
    background-color: #5D85EF;
    box-shadow: 0px 6px 13px rgba(70, 103, 190, 0.45);
    border-radius: 3px;
}

.reset-btn {
    width: 175px;
    height: 42px;

    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */


    color: #BDBDBD;

    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 3px;
}

.material-books-panel {
    background-color: blue;
    margin-top: 31px;


    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}


.mat-books-item {
    border-bottom: 1px solid #E6E6E6;
    padding-left: 25px;
    cursor: pointer;
}

.mat-books-item-text-l {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 45px;
    /* or 321% */


    color: #5D85EF;
}

.mat-books-item-text-l img{
    margin-left: 10px;
}

.mat-books-item-text {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 45px;
    /* or 321% */


    color: #5D85EF;
}

.mat-books-item-part {
    font-family: "Museo Sans Cyrl";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    text-indent: 20px;
    display: none;
    /* or 229% */

    color: #454545;
}

.mat-books-item-part  img {
    margin-left: 10px;
    margin-right: 8px;
}

.mat-book-item-section {
    font-family: "Museo Sans Cyrl";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */
    display: none;

    color: #5A5A5A;

    text-indent: 40px;
}

.mat-book-item-group {
    font-family: "Museo Sans Cyrl";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    text-indent: 60px;
    display: none;
    /* identical to box height, or 229% */


    color: #5A5A5A;
}

.mat-book-item-group-item {
    font-family: "Museo Sans Cyrl";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */
    text-indent: 80px;
    display: none;

    color: #5D85EF;

}

.display {
    display: block;
    animation: rotateX 300ms ease-in-out forwards;
}

@keyframes rotateX {
    0% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }

/* КОНЕЦ СТИЛИ ДЛЯ materials */
/* Стили для панели разделов на страницах мониторинга */
.sections-panel
{
    position: absolute;
    margin-top: 6px;
    width: 210px;

    background: #F9F9F9;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.17);
    z-index: 5;
}

.sections-panel-sections {
    display: none;
}

.sections-panel-sections > div {
    height: 50px;
}

.sections-panel-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    border-bottom: 1px solid #DFD2D2;
    transition: 0.1s;
}

.sections-panel-section-types {
    position: absolute;
    left: 210px;
    top: 35px;
    display: none;
}

.sections-panel-section-types-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
    height: 50px;
    padding: 7px 0px 10px 7px;
    background: #F9F9F9;
    border-bottom: 1px solid #DFD2D2;
    transition: 0.1s;
    box-shadow: inset 38px 0px 10px -35px rgba(0,0,0,0.17);
}

.width-inner {
    width: 190px;
}

.white {
    color: #FFFFFF !important;
}

.white-arrow {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(100%) hue-rotate(216deg) brightness(109%) contrast(101%);
}



.section-item-active {
    color: #FFFFFF;
    background: #5D85EF;
}

.sections-panel-section p {
    margin: 0px;
    padding: 0px;
}

.sections-panel-sections span{
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #353535;
}

.sections-panel-sections img {
    margin-right: 14px;
}

.sections-panel-section-types-inner {
    position: absolute;
    left: 230px;
    top: 0px;
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.17);
    display: none;
}

.bold-text {
    font-weight: bolder !important;
}


.sections-panel-section img {
    margin-right: 14px;
}


.sections-panel-button {
    position: absolute;
    left: 180px;
    bottom: 10px;
}

.sections-panel-button-el {
    border-bottom: 2px solid #535353;
    margin: 0px;
    width: 17px;
    height: 6px;
}

.sections-top-panel {
    padding: 8px 13px 0px 5px;
    height: 35px;
    position: relative;
}

.section-label {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    color: #353535;
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.transform-sections-button-1
{
    transform: rotate(-45deg);
}

.transform-sections-button-2
{
    transform:  translateY(-5.8px) translateX(3.3px) rotate(45deg);

}



/* НАЧАЛО СТИЛИ monitoring.html */
.monitoring-container {
    margin: 49px 0px 100px 0px;
}

.monitoring-container > h2 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    line-height: 39px;
    color: #000000;
}

.monitoring-container > p {
    margin-top: 12px;
    font-family: "Museo Sans Cyrl";
    font-style: normal;
    font-weight: normal;
    /* or 111% */
    color: #606060;
}

.monitoring-categories-container{
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
}

.for-details {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    color: #5D85EF;
}
/* КОНЕЦ СТИЛИ monitoring.html */


/* НАЧАЛО СТИЛИ monitoring-groups.html */
.monitoring-groups-container h2 {
    margin-bottom: 20px;
    font-family: "Fira Sans";
    font-style: normal;
    /* or 105% */
    color: #000000;
}

.monitoring-groups-container {
    margin: 49px 0px 100px 0px;
}

.monitoring-groups-p {
    font-family: "Museo Sans Cyrl";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    /* or 111% */
    color: #606060;
}

p.table-inflation-groups-title {
    margin-top: 30px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    /* or 162% */
    color: #4B4B4B;
}

.table-inflation-groups td {
    line-height: 40px;
}

.table-inflation-groups {
    margin-bottom: 50px;
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.name-column {
    width: 80%;
    padding-left: 20px;
    border-right: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
}

.coeff-column {
    border-bottom: 1px solid #E6E6E6;
    text-indent: 0px !important;
}

.table-header {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    color: #454545;
}

.table-sub-header {
    font-family: "Museo Sans Cyrl";
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    color: #646464;
    background: #FBFCFF;
}

.table-data {
    font-family: "Museo Sans Cyrl";
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    text-indent: 20px;
    color: #727272;
}

.coeff-column {
    width: 20%;
    text-align: center;
}

/* КОНЕЦ СТИЛИ monitoring-groups.html */



/*  START monitoring-producer.html  */

/* .monitoring-producer-container {
    margin: 49px 0px 200px 0px;
}

.monitoring-producer-container h2 {
    margin-bottom: 20px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    /* or 105%
    color: black;
} */

p.table-inflation-prod-title {
    margin-top: 20px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    /* or 162% */
    color: #4B4B4B;
}

.by-prod-table {
    margin-top: 30px;
    margin-bottom: 35px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.by-prod-table  td {
    line-height: 17px;
    height: 50px;
    padding: 14px 7px 16px 12px;
}

.table-data-prod {
    font-family: "Museo Sans Cyrl";
    font-style: normal;
    font-weight: normal;
    color: #727272;
}

.left-most-tc {
    border-right: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
}

.inner-tc {
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
}

.right-tc{
    border-left: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
}

.width-20 {
    width: 20%;
}
.width-15 {
    width: 15%;
}

.width-10 {
    width: 10%;
}

.width-30 {
    width: 30%;
}

.sort-arrows {
    vertical-align: 10%;
    float: right;
}
/* END monitoring-producer.html */
