// Fonts
@import '/fonts/firasans.css';
@import '/fonts/bebasneue.css';
@import '/fonts/museosanscyrl.css';

@import 'scrollbars';
@import 'variables';
@import 'theme';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~vue-select/dist/vue-select.css";
@import './parts/card.scss';
@import './parts/tabs.scss';

// FIXME legacy styles
@import './old-style.css';

$main-alignment: 1200px;

html {
    height: 100%;
}

h1 {
    font-size: 1.5rem;
}

h2 {
    font-size: 1.3rem;
}

h3 {
    font-size: 1.2rem;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 1rem;
}

body {
    font-size: 0.9rem;
    line-height: 1.1rem;

    .top-panel-variations {
        position: absolute;
        right: 0px;
        top: 10px;

        .top-panel-variation {
            display: inline-block;
            position: relative;
            margin: 15px 10px 0 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.2rem;

            a {
                font-size: 0.9rem;
                color: $black;
            }
        }
    }

    main {
        margin-top: 32px;
    }
}

footer {
    margin-top: 64px;
    margin-bottom: 16px;

    .container {
        padding: 0;
    }

    ul {
        list-style: none;

        &:first-child {
            padding: 0;
        }

        h6, #goup {
            color: $indigo;
            font-size: 0.9rem;
            line-height: 1.2rem;
            font-weight: 600;
        }

        li {
            font-size: 0.9rem;
            line-height: 1.2rem;
        }
    }
}

header {

    nav {
        background-color: $lightGray;

        .logo {
            margin: 20px;
        }
    }
}

.navbar {
    padding: 0;

    .container {
        padding: 0;
    }
}

.menu-button {
    width: 70px;
    height: 70px;
    background-color: $gray;
    border: none;
}

.navigation-list {
    list-style: none;
    margin-left: auto;
}

.navigation-item {
    margin: 15px 10px 0 10px;

    a {
        color: $black;
    }
}

.deactive-button {
    color: $darkGray;
}

a {
    color: $blue;
    text-decoration: none;
}

.link-panel {
    display: none;
    position: fixed;
    min-height: 100%;
    left: 0px;
    top: 0px;
    background: $lightGray;
    box-shadow: 3px 4px 22px rgba(0, 0, 0, 0.25);
    z-index: 3;
    padding: 50px 50px 0px 50px;
    opacity: 1;
    animation-duration: 0.5s;
    animation-name: opacitizer;
}

.link-panel-item {
    margin: 10px 0 0 0;
    line-height: 1.3rem;
}

.show {
    display: initial;
}

.card {
    border: none;
}

.paper {
    width: 100%;
    background: $white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.back-button {
    color: $white;

    &:hover {
        color: $white;
    }
}

.logo {
    font-family: Bebas Neue;
    font-weight: 700;
    font-size: 31px;
    line-height: 31px;
}

.gold {
    color: $gold;
}

#goup {
    cursor: pointer;
}

.contacts {
    font-size: 1rem;
}

.scroll-top {
    height: 60px;
    width: 60px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    border-radius: 6px;
    transform: scale(0.9);
    transition: 0.3s ease-in-out transform;
    /* for centering the icon */
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        display: block;
        width: 100%;
        color: black;
        pointer-events: none;
    }

    &:hover {
        transform: scale(1);
    }
}

table {
    td {
        p {
            margin-bottom: 0;
        }
    }
}

.rblock {
    border-radius: 10px;
    padding: 2rem;
    clear: both;
    color: #000000;
    line-height: 1.5rem;
    p {
        margin-bottom: 0;
    }
}

.attention {
    background: rgba(215, 218, 234, 0.51);
}

.warning {
    background: rgba(93, 133, 239, 0.3);
}

