// Colors
$black: #000;
$almost-black: #606060;
$blue: #5D85EF;
$smoky: #D7DAEA;
$lightGray: #F9F9F9;
$gray: #E6E6E6;
$darkGray: #BDBDBD;
$indigo: #5672CA;
$purple: #9561e2;
$pink: #f66d9b;
$red: #F83636;
$orange: #f6993f;
$gold: #ddb62d;
$yellow: #ffed4a;
$green: #16D055;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$white: #fff;
$lightBlue: #cedafa;
$darkerBlue: #c4ceec;

$primary: $blue;
$link-color: $primary;
$body-color: $almost-black;
$headings-color: $black;

$headerSize: 74px;
