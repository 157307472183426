.nav-tabs {
  overflow: hidden;
  border: none;

  .nav-link {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    color: $black;
    font-weight: 500;
    padding: 16px 32px;
    margin-bottom: 0;
    border-bottom: 2px solid $gray;

    &.active {
      background: inherit;
      color: $blue;
      border: none;
      border-bottom: $blue solid 2px;
    }
  }
}
