
/*Scrollbar custom*/
::-webkit-scrollbar {
  width:7px;
}

::-webkit-scrollbar-track {
  background: #FFF;
}

::-webkit-scrollbar-thumb {
  background: #EDEDED;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/*Moz*/
::-moz-scrollbar {
  width:7px;
}

::-moz-scrollbar-track {
  background: #FFF;
}

::-moz-scrollbar-thumb {
  background: #EDEDED;
}

::-moz-scrollbar-thumb:hover {
  background: #999;
}
/*O*/
::-o-scrollbar {
  width:7px;
}

::-o-scrollbar-track {
  background: #FFF;
}

::-o-scrollbar-thumb {
  background: #EDEDED;
}

::-o-scrollbar-thumb:hover {
  background: #999;
}
